import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SocialIcons from 'components/SocialIcons'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  content_wrap: {
    backgroundColor: theme.overrides.footerBackground,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(2),
    placeItems: 'center',
    padding: `${theme.spacing(7)}px ${theme.spacing(5)}px`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    },
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(5)}px ${theme.spacing(3)}px`
    }
  },
  footer_text: {
    color: theme.overrides.footerText
  },
  footer_link: {
    color: theme.overrides.footerText
  },
  site_links: {
    display: 'grid',
    gridGap: theme.spacing(1),
    textAlign: 'center'
  },
  social_wrap: {
    textAlign: 'center'
  }
}), {
  name: 'Footer'
})

const Footer = () => {
  const classes = useStyles()

  return (
    <div className={classes.content_wrap}>
      <div className={classes.social_wrap}>
        <SocialIcons />
        <Link
          className={classes.footer_link}
          to='/privacy'
        >
          Privacy Policy
        </Link>
      </div>
      <div className={classes.contact_wrap}>
        {/* contact info here */}
        <Typography align='center' className={classes.footer_text}>
          Copyright &copy; 2020 TyWEB Systems Inc., all rights reserved.
        </Typography>

      </div>
      <div className={classes.site_links}>
        <Link className={classes.footer_link} to='/'>Home</Link>
        <Link className={classes.footer_link} to='/about'>About</Link>
        <Link className={classes.footer_link} to='/projects'>Projects</Link>
        <Link className={classes.footer_link} to='/contact'>Contact</Link>
        <Link className={classes.footer_link} to='/sitemap'>Sitemap</Link>
      </div>
    </div>
  )
}

export default Footer
