import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  icons_grid: ({ showLabels }) => ({
    display: 'grid',
    [showLabels
      ? 'gridTemplateRows'
      : 'gridTemplateColumns'
    ]: 'repeat(6, 1fr)',
    gridGap: theme.spacing(2),
    margin: '1em auto',
    [theme.breakpoints.down('xs')]: {
      gridGap: theme.spacing(1)
    }
  }),
  icon_label_grid: ({ labelOrientation }) => ({
    display: 'grid',
    [labelOrientation === 'landscape'
      ? 'gridTemplateColumns'
      : 'gridTemplateRows'
    ]: '2.5em 1fr',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      [labelOrientation === 'landscape'
        ? 'gridTemplateColumns'
        : 'gridTemplateRows'
      ]: '1.5em 1fr'
    }
  }),
  icon: {
    width: '2.5em !important',
    height: '2.5em !important',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '1.5em !important',
      height: '1.5em !important'
    }
  }
}))

const SocialIcons = ({ showLabels = false, labelOrientation = 'landscape' }) => {
  const [
    doSocialIconsNeedFetching,
    setDoSocialIconsNeedFetching
  ] = useState(true)
  const [socialIcons, setSocialIcons] = useState([])
  const classes = useStyles({
    showLabels,
    labelOrientation
  })

  useEffect(() => {
    if (doSocialIconsNeedFetching) {
      const fetchSocialIcons = async () => {
        const { SocialIcon } = await import('react-social-icons')
        const newSocialIcons = Object.entries({
          LinkedIn: 'https://linkedin.com/in/ty-everett',
          GitHub: 'https://github.com/ty-everett',
          Facebook: 'https://www.facebook.com/tyler.sands.33',
          Instagram: 'https://www.instagram.com/ty.everett49/',
          YouTube: 'https://www.youtube.com/channel/UCkAQenZBQj2aCcD-6DzYddw',
          Twitter: 'https://twitter.com/tyeverett11'
        }).map(([network, url], index) => showLabels ? (
          <a href={url}>
            <div className={classes.icon_label_grid}>
              <SocialIcon
                url={url}
                key={index}
                fgColor='#fff'
                className={classes.icon}
              />
              <Typography>
                {network}
              </Typography>
            </div>
          </a>
        )
          : (
            <SocialIcon
              url={url}
              key={index}
              fgColor='#fff'
              className={classes.icon}
            />
          )
        )
        setSocialIcons(newSocialIcons)
      }
      fetchSocialIcons()
      setDoSocialIconsNeedFetching(false)
    }
  }, [
    doSocialIconsNeedFetching,
    classes.icon,
    classes.icon_label_grid,
    showLabels
  ])

  useEffect(() => {
    setDoSocialIconsNeedFetching(true)
  }, [showLabels])

  return (
    <div className={classes.icons_grid}>
      {socialIcons}
    </div>
  )
}

export default SocialIcons
