import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const PageScrollListener = () => {
  const history = useHistory()

  useEffect(() => {
    return history.listen(location => {
      window.scrollTo(0, 0)
    })
  }, [history])

  return null
}

export default PageScrollListener
