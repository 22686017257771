import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  Card,
  Hidden,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { Link, withRouter } from 'react-router-dom'
import Logo from 'components/Logo'
import resources from 'res/resources'
import clsx from 'clsx'
import CorrectlySizedImage from 'components/CorrectlySizedImage'

const links = [
  {
    destination: '/',
    label: 'Home'
  },
  {
    destination: '/projects',
    label: 'Projects'
  },
  {
    destination: '/resources',
    label: 'Resources'
  },
  {
    destination: '/blog',
    label: 'Blog'
  },
  {
    destination: '/about',
    label: 'About'
  }
]

const useStyles = makeStyles(theme => ({
  sticky_wrap: {
    width: '100%',
    position: 'sticky',
    top: '0px',
    left: '0px',
    zIndex: 5
  },
  navbar_wrap: {
    maxWidth: '1440px',
    margin: 'auto',
    padding: '0.5em 1.5em',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      gridGap: theme.spacing(1)
    }
  },
  links_wrap: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    justifySelf: 'end',
    width: '100%',
    maxWidth: '35em'
  },
  menu_icon: {
    justifySelf: 'right',
    alignSelf: 'center'
  },
  menu: {
    minWidth: '33vw',
    [theme.breakpoints.down('xs')]: {
      minWidth: '50vw'
    }
  },
  resources_wrap: {
    width: 'min(1440px, 100vw)',
    marginTop: theme.spacing(9),
    '& > ul': {
      display: 'grid',
      padding: '0px',
      gridTemplateColumns: 'repeat(4, 1fr)',
      boxSizing: 'border-box',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridRowGap: theme.spacing(3)
      }
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7)
    }
  },
  resource: {
    color: 'unset',
    userSelect: 'none',
    display: 'grid',
    placeItems: 'start',
    gridTemplateRows: '4em 8em 7em',
    alignItems: 'start',
    transition: 'all 0.5s',
    background: 'linear-gradient(to bottom, #E7F3FF, #fff, #fff)',
    backgroundSize: '200% 200%',
    backgroundPosition: '0% 100%',
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    '&:hover': {
      backgroundPosition: '0% 0%'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: '4em 12em 7em'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateRows: '3em 7em 7em'
    }
  },
  disabled_resource: {
    opacity: '0.5'
  },
  resource_image: {
    display: 'block',
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    margin: 'auto',
    width: '6em',
    height: '6em'
  },
  link: {
    textDecoration: 'none',
    padding: '0.5em 0px',
    color: theme.overrides.navTextInactive,
    whiteSpace: 'nowrap',
    transition: 'all 0.5s',
    '&:visited': {
      color: theme.overrides.navTextInactive
    },
    '&:active': {
      color: theme.overrides.navTextActive
    },
    '&:hover': {
      color: theme.overrides.navTextActive
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0.25em 0px'
    }
  }
}))

const NO_BLOG = process.env.REACT_APP_DISABLE_BLOG

const Navbar = ({ history }) => {
  const classes = useStyles()
  const menuRef = useRef()
  const resourcesRef = useRef()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null)

  const handleClick = location => {
    setMenuAnchorEl(null)
    history.push(location)
  }

  return (
    <Card square className={classes.sticky_wrap}>
      <div className={classes.navbar_wrap}>
        <Link to='/'>
          <Logo forwardRef={resourcesRef} />
        </Link>
        <Hidden xsDown>
          <div className={classes.links_wrap} role='navigation'>
            {links
              .filter(link => NO_BLOG ? link.destination !== '/blog' : true)
              .map((link, i) => (
                <Link
                  key={i}
                  to={link.destination}
                  className={classes.link}
                  onClick={link.label === 'Resources' ? e => {
                    e.preventDefault()
                    setResourcesAnchorEl(resourcesRef.current)
                  } : undefined}
                >
                  {link.label}
                </Link>
              ))}
          </div>
          <Menu
            open={resourcesAnchorEl !== null}
            onClose={() => setResourcesAnchorEl(null)}
            onSelect={() => setResourcesAnchorEl(null)}
            anchorEl={resourcesAnchorEl}
            classes={{
              paper: classes.resources_wrap
            }}
          >
            {resources.map((resource, index) => (
              <Link
                className={clsx(
                  classes.resource,
                  resource.comingSoon ? classes.disabled_resource : undefined
                )}
                key={index}
                role='button'
                aria-label={resource.title}
                to={resource.destination}
                onClick={e => {
                  if (resource.comingSoon) {
                    e.preventDefault()
                  } else {
                    setResourcesAnchorEl(null)
                  }
                }}
              >
                <Typography variant='h3'>
                  {resource.title}
                </Typography>
                <Typography>
                  {resource.description}
                </Typography>
                <CorrectlySizedImage
                  src={resource.image}
                  alt={resource.title}
                  className={classes.resource_image}
                />
              </Link>
            ))}
          </Menu>
        </Hidden>
        <Hidden smUp>
          <IconButton
            ref={menuRef}
            onClick={() => setMenuAnchorEl(menuRef.current)}
            className={classes.menu_icon}
            aria-label='Open navigation menu'
          >
            <MenuIcon />
          </IconButton>
          <Menu
            open={menuAnchorEl !== null}
            onClose={() => setMenuAnchorEl(null)}
            onSelect={() => setMenuAnchorEl(null)}
            anchorEl={menuAnchorEl}
            classes={{
              paper: classes.menu
            }}
          >
            {links
              .filter(link => NO_BLOG ? link.destination !== '/blog' : true)
              .map((link, i) => (
                <MenuItem key={i} onClick={() => handleClick(link.destination)}>
                  {link.label}
                </MenuItem>
              ))}
          </Menu>
        </Hidden>
      </div>
    </Card>
  )
}

export default withRouter(Navbar)
