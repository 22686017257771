import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import CorrectlySizedImage from 'components/CorrectlySizedImage'

const useStyles = makeStyles(theme => ({
  logo_wrap: {
    display: 'grid',
    placeItes: 'center',
    gridTemplateColumns: ({ size = 2 }) => `${size * 2.25}em auto`,
    width: 'fit-content'
  },
  logo_img: {
    width: ({ size = 2 }) => `${size * 2.25}em`
  },
  logo_text: {
    fontWeight: '100',
    fontSize: ({ size = 2 }) => `${size * 0.8}em`,
    display: 'inline',
    userSelect: 'none',
    cursor: 'default',
    color: theme.overrides.logoText,
    justifySelf: 'start'
  }
}), {
  name: 'Logo'
})

const Logo = ({ className, size, forwardRef }) => {
  const classes = useStyles({ size })
  return (
    <div
      className={clsx(classes.logo_wrap, className)}
      role='img'
      aria-label='TyWEB Logo - An EKG graph forms the letters "T" and "W"'
      ref={forwardRef}
    >
      <CorrectlySizedImage
        src='/assets/images/logo.svg'
        className={classes.logo_img}
        alt=''
      />
      <Typography
        className={classes.logo_text}
        component='div'
      >
        Ty&nbsp;Everett
      </Typography>
    </div>
  )
}

export default Logo
