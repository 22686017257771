import React, { lazy, Suspense, useState, useEffect } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Navbar from 'components/Navbar'
import Theme from 'components/Theme'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import Footer from 'components/Footer'
import PageScrollListener from 'components/PageScrollListener'
import 'react-lazy-load-image-component/src/effects/opacity.css'

const Home = lazy(() => import('pages/Home'))
const Blog = lazy(() => import('pages/Blog'))
const Projects = lazy(() => import('pages/Projects'))
const About = lazy(() => import('pages/About'))
const Contact = lazy(() => import('pages/Contact'))
const FourOhFour = lazy(() => import('pages/FourOhFour'))
const GenerateSitemap = lazy(() => import('pages/GenerateSitemap'))
const Sitemap = lazy(() => import('pages/Sitemap'))
const Privacy = lazy(() => import('pages/Privacy'))
const Resources = lazy(() => import('pages/Resources'))
const Resume = lazy(() => import('pages/Resume'))

const NO_BLOG = process.env.REACT_APP_DISABLE_BLOG

export default () => {
  const [CMSRoutes, setCMSRoutes] = useState([])
  const [CMSContentNeedsFetching, setCMSContentNeedsFetching] = useState(true)
  const [isCMSContentDoneLoading, setIsCMSContentDoneLoading] = useState(false)

  // The CMS content is fetched in the background after the app loads
  useEffect(() => {
    if (CMSContentNeedsFetching && !NO_BLOG) {
      const fetchCMSContent = async () => {
        const CMSContent = await import('CMS/posts')
        const newCMSRoutes = await Promise.all(
          Object.values(CMSContent).map(async (route, index) => {
            try {
              if (!route.publish) {
                return null
              }
              const { default: TemplateComponent } = await import(
                `CMS/templates/${route.template}`
              )
              const HydratedTemplate = () => (
                <TemplateComponent
                  data={route}
                />
              )
              return (
                <Route
                  key={index}
                  path={route.url}
                  component={HydratedTemplate}
                  exact
                />
              )
            } catch (e) {
              if (e.code === 'MODULE_NOT_FOUND') {
                return (
                  <Route
                    key={index}
                    path={route.url}
                    component={() => (
                      <div
                        style={{
                          padding: '1em'
                        }}
                      >
                        Unable to find template <b>{route.template}</b> which is
                        required by this page.
                      </div>
                    )}
                    exact
                  />
                )
              } else {
                throw e
              }
            }
          })
        )
        setCMSRoutes(newCMSRoutes)
        setIsCMSContentDoneLoading(true)
      }
      fetchCMSContent()
      setCMSContentNeedsFetching(false)
    }
  }, [CMSContentNeedsFetching])

  return (
    <Theme>
      <HelmetProvider>
        <Helmet>
          <meta charset='utf-8' />
          <link rel='shortcut icon' href='https://tyweb.us/favicon.ico' />
          <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
          <meta name='theme-color' content='#ffffff' />
          <link rel='manifest' href='https://tyweb.us/manifest.json' />
          <title>TyWEB — Ty Everett Homepage & Blog</title>
          <meta name='title' content='TyWEB — Ty Everett Homepage & Blog' />
          <meta
            name='description'
            content="This website showcases my projects, hosts my blog and provides a platform for my ideas. If you like what you read, reach out to me and let's work together."
          />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.href} />
          <meta
            property='og:title'
            content='TyWEB — Ty Everett Homepage & Blog'
          />
          <meta
            property='og:description'
            content="This website showcases my projects, hosts my blog and provides a platform for my ideas. If you like what you read, reach out to me and let's work together."
          />
          <meta property='og:image' content='https://tyweb.us/banner.png' />
          <meta property='twitter:card' content='summary_large_image' />
          <meta property='twitter:url' content={window.location.href} />
          <meta
            property='twitter:title'
            content='TyWEB — Ty Everett Homepage & Blog'
          />
          <meta
            property='twitter:description'
            content="This website showcases my projects, hosts my blog and provides a platform for my ideas. If you like what you read, reach out to me and let's work together."
          />
          <meta
            property='twitter:image'
            content='https://tyweb.us/banner.png'
          />
        </Helmet>
        <BrowserRouter>
          <Navbar />
          <div
            style={{
              minHeight: 'calc(100vh - 4em)', // Need to subtract navbar height
              maxWidth: '100vw',
              overflowX: 'hidden',
              display: 'grid',
              gridTemplateRows: '1fr auto'
            }}
          >
            <Suspense fallback={<div />}>
              <Switch>
                {/*
                Render CMS routes first to ensure URLs are respected
              */}
                {isCMSContentDoneLoading && CMSRoutes}

                <Route path='/' component={Home} exact />
                {!NO_BLOG && <Route path='/blog' component={Blog} exact />}
                <Route path='/projects' component={Projects} />
                <Route path='/about' component={About} exact />
                <Route path='/contact' component={Contact} exact />
                <Route
                  path='/generate-sitemap'
                  component={GenerateSitemap}
                  exact
                />
                <Route path='/sitemap' component={Sitemap} exact />
                <Route path='/privacy' component={Privacy} exact />
                <Route path='/resources' component={Resources} exact />
                <Route path='/resume' component={Resume} exact />

                {/*
                Only render the 404 page if CMS routes have finished loading
              */}
                {(isCMSContentDoneLoading || NO_BLOG) && (
                  <Route default component={FourOhFour} />
                )}
              </Switch>
            </Suspense>
            <Footer />
            <PageScrollListener />
          </div>
        </BrowserRouter>
      </HelmetProvider>
    </Theme>
  )
}
