import React, { useRef } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles({
  div: {
    width: '100%',
    '& > span': {
      width: '100%'
    }
  }
})

const CorrectlySizedImage = ({ lazyLoad = true, ...props }) => {
  const classes = useStyles()
  const imgRef = useRef()
  let imageWidth
  if (imgRef.current && imgRef.current.offsetWidth) {
    imageWidth = imgRef.current.offsetWidth
  }
  return (
    <div
      ref={imgRef}
      className={clsx(classes.div, props.className)}
      style={{ width: '100%' }}
    >
      {lazyLoad ? (
        <LazyLoadImage
          effect='opacity'
          title={props.alt}
          {...props}
          style={{ width: '100%' }}
          src={`${props.src}?nf_resize=fit&w=${imageWidth}`}
        />
      ) : (
        <img
          title={props.alt}
          {...props}
          style={{ width: '100%' }}
          src={`${props.src}?nf_resize=fit&w=${imageWidth}`}
        />
      )}
    </div>
  )
}

export default CorrectlySizedImage
