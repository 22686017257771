export default [
  {
    image: '/assets/images/dph-icon.svg',
    title: 'Digital Privacy Handbook',
    description: 'Get the tools you need to protect your privacy and defend your data in a world that constantly tries to erode your digital soverignty.',
    destination: '/digital-privacy-handbook',
    ctaText: 'Learn More',
    ctaType: 'button',
    comingSoon: true,
    interactive: false
  },
  {
    image: '/assets/images/newsletter-icon.svg',
    title: 'Privacy Newsletter',
    description: 'Stay in the know about the latest trens in the internet privacy landscape with weekly updates sent straight to your inbox.',
    destination: '/newsletter',
    ctaText: 'Subscribe Now',
    ctaType: 'button',
    comingSoon: true,
    interactive: false
  },
  {
    image: '/assets/images/esg-icon.svg',
    title: 'Email Security Guide',
    description: 'Websites like Gmail and Yahoo can spy on your emails or even share them with the government. Learn what you can do to keep your messages private.',
    destination: '/email-security-guide',
    ctaText: 'Download',
    ctaType: 'button',
    comingSoon: true,
    interactive: false
  },
  {
    image: '/assets/images/blue-bitcoin.svg',
    title: 'Internet Censorship Guide',
    description: 'Two thirds of the world\'s internet users live under government censorship. Learn the tools and techniques you can use to secure your digital rights.',
    destination: '/combatting-internet-censorship',
    ctaText: 'Read More...',
    ctaType: 'link'
  }
]
